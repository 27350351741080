<template>
  <main>
    <PageHeader :items="breadcrumb" />

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col sm="7" lg="8" xl="10">
              <h5>
                Aprovação pelo fornecedor -
                <span class="text-primary" v-if="diagnosis">
                  Proposta de {{ diagnosis.pessoa.pessoa_nome }}
                </span>
              </h5>

              <div style="width: 100%">
                <p style="color: #74788d; font-size: 13; font-family: poppins">
                  Confira abaixo a lista de documentos e informações que o
                  Corretor enviou para completar a venda e efetivar os produtos.
                </p>
              </div>
            </b-col>

            <b-col sm="5" lg="4" xl="2">
              <div>Corretor responsável</div>
              <div
                class="font-weight-bold"
                v-if="diagnosis && diagnosis.usuario.pessoa"
              >
                {{ diagnosis.usuario.pessoa.pessoa_nome }}
              </div>
            </b-col>
          </b-row>

          <div style="background-color: #f7f7fa">
            <div
              class="d-flex align-items-center rounded-top bg-secondary text-white"
              style="padding: 0.5rem"
            >
              <i
                class="fas fa-file-alt"
                size="40"
                style="margin-right: 1rem; font-size: 1rem; color: #fff"
              ></i>
              <span>Contratos e apólices</span>
            </div>

            <div class="px-3 py-2" v-if="diagnosis">
              <div
                v-for="(policy, index) in diagnosis.diagnostico_apolices"
                :key="policy.diagnostico_apolice_id"
              >
                <b-row class="my-4">
                  <b-col md="5" lg="4" v-if="!policy.editMode">
                    <p class="font-weight-bold mb-1">{{ policy.titulo }}</p>
                    <p class="mb-3" v-if="policy.download_url">
                      Documento salvo dia
                      {{ policy.data_alteracao | formatDate }}
                    </p>

                    <div v-if="!policy.download_url">Não enviado</div>

                    <a
                      :href="policy.download_url"
                      target="_blank"
                      v-if="policy.download_url"
                    >
                      <b-button-group class="w-100">
                        <b-button
                          class="border-right-0"
                          style="
                            width: 43px;
                            background: #dddfe3;
                            border-color: #ced4da;
                          "
                        >
                          <i
                            class="fa fa-eye"
                            style="font-size: 0.95rem; color: #495057"
                          />
                        </b-button>

                        <b-button
                          class="border-right-0 border-left-0"
                          style="
                            background: #eff2f7;
                            color: #495057 !important;
                            border-color: #ced4da;
                          "
                        >
                          Visualizar
                        </b-button>

                        <b-button
                          class="border-left-0 w-100 text-left"
                          style="
                            background: #f7f7fa;
                            color: #495057 !important;
                            border-color: #ced4da;
                          "
                        >
                          {{ policy.nome_original }}
                        </b-button>
                      </b-button-group>
                    </a>
                  </b-col>

                  <b-col md="5" lg="4" v-if="policy.editMode">
                    <p class="font-weight-bold mb-2">Título do arquivo</p>

                    <div class="mb-3">
                      <b-form-input
                        v-model="policy.titulo"
                        type="text"
                        placeholder="Nome do arquivo"
                      />
                    </div>

                    <div v-if="policy.download_url">
                      {{ policy.nome_original }} - Enviado dia
                      {{ policy.data_alteracao | formatDate }}
                    </div>

                    <b-form-file
                      style="cursor: pointer"
                      placeholder="Escolha um arquivo ou solte-o aqui..."
                      drop-placeholder="Solte o arquivo aqui..."
                      v-model="policy.file"
                    />
                  </b-col>

                  <b-col md="5" lg="5" v-if="!policy.editMode">
                    <p class="mb-1 font-weight-bold">Comentário</p>

                    <div
                      class="p-3 rounded border"
                      style="border-color: #ced4da !important"
                    >
                      {{ policy.comentario_especialista }}
                    </div>
                  </b-col>

                  <b-col md="5" lg="5" v-if="policy.editMode">
                    <p class="font-weight-bold">Comentário</p>

                    <b-form-textarea
                      required
                      rows="5"
                      placeholder="Texto do comentário..."
                      v-model="policy.comentario_especialista"
                    />
                  </b-col>

                  <b-col md="4" lg="3" class="d-flex align-items-center pl-3">
                    <b-button
                      variant="success"
                      class="rounded-circle mr-3"
                      @click="() => editPolicy(index)"
                      v-if="!policy.editMode"
                    >
                      <i class="bx bx-pencil" />
                    </b-button>

                    <b-button
                      variant="danger"
                      class="rounded-circle"
                      @click="() => deletePolicy(policy)"
                      v-if="!policy.editMode"
                    >
                      <i class="bx bx-trash" />
                    </b-button>

                    <b-button
                      variant="danger"
                      class="rounded-circle mr-3"
                      @click="() => cancelEditPolicy(index)"
                      v-if="policy.editMode"
                    >
                      <i class="bx bx-x" />
                    </b-button>

                    <b-button
                      variant="primary"
                      class="rounded-circle mr-3"
                      @click="() => updatePolicy(policy)"
                      v-if="policy.editMode"
                    >
                      <i class="bx bx-check" />
                    </b-button>
                  </b-col>
                </b-row>

                <hr />
              </div>

              <b-row class="my-4">
                <b-col md="5" lg="4">
                  <p class="font-weight-bold mb-2">Título do arquivo</p>

                  <div class="mb-3">
                    <b-form-input
                      v-model="fileTitle"
                      type="text"
                      placeholder="Nome do arquivo"
                    />
                  </div>

                  <b-form-file
                    style="cursor: pointer"
                    placeholder="Escolha um arquivo ou solte-o aqui..."
                    drop-placeholder="Solte o arquivo aqui..."
                    v-model="file"
                  />
                </b-col>

                <b-col md="5" lg="5">
                  <p class="font-weight-bold">Comentário</p>

                  <b-form-textarea
                    required
                    rows="5"
                    placeholder="Texto do comentário..."
                    v-model="comment"
                  />
                </b-col>
              </b-row>

              <div>
                <b-button
                  style="width: 200px"
                  variant="primary"
                  class="font-weight-bold px-5"
                  @click="save"
                >
                  SALVAR
                </b-button>
              </div>

              <hr />

              <div>
                <b-button
                  style="width: 200px"
                  variant="dark-primary"
                  class="font-weight-bold px-5"
                  @click="addPolicy"
                >
                  ADICIONAR +1
                </b-button>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import { cloneObject } from '@/helpers';

import PageHeader from '@/components/PageHeader';

import {
  requestDiagnosisSpecialist,
  updatePhaseSpecialist,
  uploadPolicyFile,
  uploadDiagnosisPolicy,
  requestPolicyDiagnosis,
  updateDocumentPolicy,
  deleteDiagnosisPolicy
} from '@/services/requests/diagnosis';

export default {
  name: 'SupplierApproval',
  components: {
    PageHeader
  },
  data() {
    return {
      diagnosis: null,
      fileTitle: '',
      file: null,
      comment: ''
    };
  },
  methods: {
    loadDiagnosis() {
      requestDiagnosisSpecialist(this.diagnosisId)
        .then((res) => {
          this.diagnosis = res.data.result.data;
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    updatePolicyFileRequest(policy, file) {
      return uploadPolicyFile(policy, file);
    },

    addPolicy() {
      const data = {
        diagnostico_id: this.diagnosis.diagnostico_id,
        titulo: this.fileTitle,
        comentario_especialista: this.comment
      };

      uploadDiagnosisPolicy(data)
        .then((res) => {
          const policy = res.data.result.data;

          this.updatePolicyFileRequest(policy, this.file)
            .then(() => {
              this.loadDiagnosis();
              this.resetForm();
            })
            .catch(() => {
              this.$store.state.modal.isOpenModalErrorRequest = true;
            });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    save() {
      const data = {
        estagio_id: 6
      };

      if (
        this.diagnosis.diagnostico_historico_especialista.estagio_id >=
        data.estagio_id
      ) {
        this.$router.push({ name: 'AwaitingConsultant' });
        return;
      }

      const diagnosisId = this.diagnosis.diagnostico_id;

      updatePhaseSpecialist(diagnosisId, data)
        .then(() => {
          this.$router.push({
            name: 'AwaitingConsultant',
            params: { diagnosisId }
          });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    viewFile(policy) {
      requestPolicyDiagnosis(policy.diagnostico_apolice_id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', policy.nome_original);

          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    resetForm() {
      this.fileTitle = '';
      this.file = null;
      this.comment = '';
    },

    editPolicy(index) {
      const policy = cloneObject(this.diagnosis.diagnostico_apolices[index]);
      policy.original = cloneObject(policy);
      policy.editMode = true;

      this.$set(this.diagnosis.diagnostico_apolices, index, policy);
    },

    cancelEditPolicy(index) {
      const policy = cloneObject(
        this.diagnosis.diagnostico_apolices[index].original
      );
      policy.editMode = false;

      this.$set(this.diagnosis.diagnostico_apolices, index, policy);
    },

    updatePolicy(policy) {
      const data = {
        titulo: policy.titulo,
        comentario_especialista: policy.comentario_especialista
      };

      updateDocumentPolicy(policy.diagnostico_apolice_id, data)
        .then(() => {
          if (!policy.file) {
            this.loadDiagnosis();
            return;
          }

          this.updatePolicyFileRequest(policy, policy.file)
            .then(() => {
              this.loadDiagnosis();
            })
            .catch(() => {
              this.$store.state.modal.isOpenModalErrorRequest = true;
            });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    deletePolicy(policy) {
      const confirm = window.confirm('Deseja realmente excluir esta apólice?');

      if (!confirm) return;
      deleteDiagnosisPolicy(policy.diagnostico_apolice_id)
        .then(() => {
          this.loadDiagnosis();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    diagnosisId() {
      return this.$route.params.diagnosisId;
    },
    breadcrumb() {
      return [
        {
          text: 'Inicial',
          href: '/'
        },
        {
          text: 'Painel de atividades inicial',
          href: '/activity-panel'
        },
        {
          text: 'Preparar cotação',
          href: `/activity-panel/proposal-quotation/${this.diagnosisId}`
        },
        {
          text: 'Verificar resposta da proposta',
          href: `/activity-panel/check-proposal-response/${this.diagnosisId}`
        },
        {
          text: 'Efetivação da proposta',
          href: `/activity-panel/proposal-execution/${this.diagnosisId}`
        },
        {
          text: 'Imputar propostas',
          href: `/activity-panel/input-proposals/${this.diagnosisId}`
        },
        {
          text: 'Aprovação pelo fornecedor',
          active: true
        }
      ];
    }
  },
  mounted() {
    this.loadDiagnosis();
  }
};
</script>
